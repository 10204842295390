import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const authorizedEmails = [
    "parth@divtechnosoft.com",
    "russell.paul@outlook.com",
    "admin@unknownlimits.co",
  ];

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
    });
    return () => unsubscribe();
  }, []);

  const onSignIn = () => {
    setError("");
    if (!authorizedEmails.includes(email)) {
      setError("You are not authorized to access the portal");
      return;
    }

    setLoading(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setError(error.message);
      });
  };

  if (user) {
    return <Redirect to='/' />;
  }

  return (
    <div className='container vertical-center'>
      <div className='auth-wrapper'>
        <div className='auth-inner'>
          <h3>Sign In</h3>
          {error && <div className='alert alert-danger'>{error}</div>}
          <div className='form-group'>
            <label>Email address</label>
            <input
              type='email'
              className='form-control'
              placeholder='Enter email'
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              disabled={loading}
            />
          </div>
          <div className='form-group'>
            <label>Password</label>
            <input
              type='password'
              className='form-control'
              placeholder='Enter password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={loading}
            />
          </div>
          <button
            className='btn btn-primary btn-block'
            onClick={onSignIn}
            disabled={loading}>
            {loading ? "Signing In..." : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
}
